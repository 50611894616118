import axios from 'axios';
import { message } from 'antd';

const apiLocationUrl = 'api-i-converge.jd.com';
axios.defaults.timeout = 100000;
axios.defaults.withCredentials = true;

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  config => {
    // @ts-ignore
    config.headers = {
      ...config.headers,
      'X-Requested-With': 'XMLHttpRequest',
      'X-jd-ts': new Date().getTime(),
    };
    if (/http/gi.test(config?.url || '')) {
      config.url = config?.url;
    } else {
      config.url = '//' + apiLocationUrl + config?.url;
    }


    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log("请求出错：", error);
  },
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url: string, params = {}, args?: any): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        withCredentials: true,
        headers: {
          ...args
        },
      })
      .then(response => {
        resolve(response ? response?.data : '');
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url: string, data: any, args?: {}): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          ...args
        },
      })
      .then(
        response => {
          //关闭进度条
          resolve(response?.data);
        },
        err => {
          reject(err);
        },
      );
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function jsonPost(url: string, data: any, args?: {}): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          ...args
        },
      })
      .then(
        response => {
          //关闭进度条
          resolve(response.data);
        },
        err => {
          reject(err);
        },
      );
  });
}

/**
 * form post
 * @param url
 * @param data
 * @returns {Promise}
 */

export function formPost(url: string, data: any, args?: {}): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...args
        },
      })
      .then(
        response => {
          //关闭进度条
          resolve(response.data);
        },
        err => {
          reject(err);
        },
      );
  });
}

/**
 * 封装download请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function download(url: string, data: any, args?: {}): Promise<any> {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    axios
      .post(url, formData, {
        headers: {
          ...args
        },
        responseType: 'blob',
      })
      .then(
        response => {
          let filename = response.headers['content-disposition']
            ? response.headers['content-disposition'].split('filename=')[1] || ''
            : '';
          filename = decodeURI(filename);
          //关闭进度条
          resolve({ blob: new Blob([response.data]), filename: filename });
        },
        err => {
          reject(err);
        },
      );
  });
}

//统一接口处理，返回数据
export function http(fecth: any, url: any, param: {} | undefined, args?: any) {
  const _data = '';
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case 'get':
        get(url, param, args)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            console.log('get request GET failed.', error);
            reject(error);
          });
        break;
      case 'post':
        post(url, param, args)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      case 'jsonpost':
        jsonPost(url, param, args)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      case 'formPost':
        formPost(url, param, args)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      case 'download':
        download(url, param, args)
          .then(function(response) {
            resolve(response);
          })
          .catch(function(error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      default:
        break;
    }
  });
}

export function messageHttp(method: string, url: string, params: object, args?: {}) {
  return new Promise((resolve, reject) => {
    http(method, url, params, args).then(
      (res: any) => {
        if (res.success || res?.message == 'success') {
          resolve(res);
        } else {
          message.error(res.msg || res.message);
          resolve(res);
        }
      },
      error => {
        console.log('网络异常~', error);
        message.error('网络异常~');
        reject({ msg: '网络异常~' });
      },
    );
  });
}
