import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { pageframeOnload, MD5, getCookie, getThemeColor } from '@/utils';
import { getS2 } from './util';
import { getPrice } from '@/axios/home';
import {colorAjax} from "@/axios/colorAjax";

// 选型初始化数据
const initData = {
  "category": [],
  "productList": [],
  "pageObj": {
    pageNum: 1,
    pageSize: 50,
    total: 0
  }
}
let checkboxChecked: any[] = []; // 存储当前选项
let pageObj = { // 存储当前分页信息
  pageNum: 1,
  pageSize: 50,
}

/* eslint-disable */
const Index = (props: { itemObj: { cateSelected: string; cateStr: string } }) => {
  const { itemObj } = props;

  const [modelMc, setModelMc] = useState<any>(); // 解析后数据
  const [showMessage, setShowMessage] = useState<boolean>(false); // 显示暂无记录
  const [initLoading, setinitLoading] = useState<boolean>(true); // 首屏loading
  const hex_md5 = MD5().hex_md5;

  // 根据选择的选项attrId和attrValueId反推出 index_i格式，重置数据后反选产品选型左侧筛选项
  // const formatCheckboxChecked = (checkboxChecked: any, extAttrList: any) => {
  //   return checkboxChecked.map((e: any) => {
  //     const i = extAttrList?.findIndex((el: { attrId: string; attrName: string; extAttrValueVoList: any[] }) => e.attrId === el.attrId);
  //     const j = extAttrList[i]?.extAttrValueVoList?.findIndex((evv: { attrValueId: string; attrValueName: string }) => evv.attrValueId === e.attrValueId);
  //
  //     return `${i}_${j}`
  //   })
  // }

  const onActionHandler1008 = (data: any) => {
    // 点击修改购买数量
    if (data.actionType === 'checkStock') {
      setTimeout(() => {
        data.callback(data.skuMsg);
      }, 0);
    }
    // 加车直接跳转购物车
    if (data.actionType === 'addToCart') {
      const obj: any = {}

      data.productList.forEach((pl: any) => {
        obj[pl.skuId] = pl.amount
      })

      // @ts-ignore
      window.log('lectotype', 'add_cart', JSON.stringify({
        user_pin: getCookie('pin'),
        skuid: Object.keys(obj).join(',')
      }));

      setTimeout(() => {
        window.open(`//cart.jd.com/gate.action?pids=${Object.keys(obj).join(',')}&pnums=${Object.values(obj).join(',')}&type=1`)
        data.callback([])
      }, 0)
    }
    // 点击店铺
    if (data.actionType === 'linkToShop') {
      setTimeout(() => {
        window.open(`https://mall.jd.com/index-${data.shopId}.html?from=pc`)
      }, 0)
    }
    // 点击dongdong
    if (data.actionType === 'linkTodongdong') {
      setTimeout(() => {
        window.open('https://jdcs.jd.com/pop/chat/index.action?venderId=1&appId=jd.waiter&customerAppId=im.customer&entry=jd_web_gypzndg')
      }, 0)
    }
    // 切换筛选项
    if (data.actionType === 'search') {
      const result = data?.searchItem?.map((e: string) => {
        const index: any[] = e.split('_');
        return {
          attrId: index[0],
          attrValueId: index[1],
        }
      })
      checkboxChecked = data?.searchItem
      reloadPlist({
        cateId: itemObj?.cateSelected,
        pageNum: 1,
        pageSize: 50,
        extAttrList: result
      }, data.callback)
    }
    // 切换分页
    if (data.actionType === 'changepage') {
      pageObj.pageNum = data.pageObj.current
      pageObj.pageSize = 50
      const result = checkboxChecked?.map((e: string) => {
        const index: any[] = e.split('_');
        return {
          attrId: index[0],
          attrValueId: index[1],
        }
      })
      reloadPlist({
        cateId: itemObj?.cateSelected,
        pageNum: pageObj.pageNum,
        pageSize: 50,
        extAttrList: result
      }, data.callback)
    }
  };

  // 格式化数据
  const getFormat = useCallback((data: any) => {
    const result = {
      category: data?.extAttrList?.map((e: {
        attrName: string,
        attrId: number | string,
        extAttrValueVoList: { attrValueId: string | number, attrValueName: string }[]
      }) => {
        return {
          label: e.attrName,
          name: e.attrId,
          values: e.extAttrValueVoList.map(exvv => {
            return {
              name: exvv.attrValueName,
              id: exvv.attrValueId
            }
          })
        }
      }),
      pageObj: {
        current: data?.pageNum || 1,
        pageSize: 50,
        total: data?.resultCount || 0
      },
      productList: data?.productList?.map((e: {
        attr: string | null,
        mainImage: string | null,
        maxBuyNum: number | null,
        minBuyNum: number | null,
        price: string | null,
        shopName: string | null,
        shopId: string | null,
        skuId: string | null,
        skuName: string | null,
        status: string | number | null
      }) => {
        return {
          mkuId: e.skuId,
          mkuName: e.skuName,
          imagePath: e.mainImage,
          price: e.price,
          model: e.shopName,
          shopId: e.shopId,
          skuId: e.skuId,
          noGoods: 0,
          noSale: 0,
          areaLimit: 0,
          noPool: 0,
          attr: e.attr,
          minLimit: e?.minBuyNum && e?.minBuyNum < 1 ? 0 : e.minBuyNum,
          maxLimit: e?.maxBuyNum && e?.maxBuyNum < 1 ? 0 : e.maxBuyNum,
        }
      }),
      checkboxChecked: checkboxChecked,
    }
    return result
  }, [checkboxChecked])

  const formatQueryData = (content: string, s1: string) => {
    return hex_md5(`${content.slice(4, 8)}${s1}${content.slice(0, 3)}${getCookie('ipLoc-djd')}`)
  }

  const getPriceOfProductList = (productList: any[]) => {
    return new Promise((resolve, reject)=>{
      const skuChunkNum = 10;
      let skuChunksCount = Math.ceil(productList.length/skuChunkNum);
      for (let index = 0; index < productList.length; index += skuChunkNum) {
        // 将原始数据30个为一组做拆分
        const productChunksItem = productList.slice(index, index+skuChunkNum)
        // 抽取sku列表
        const skuChunksItem = productChunksItem.map(item=>{
          return item.skuId;
        });
        // 分组获取价格
        getPrice({allSkuList: skuChunksItem}).then((res: any) => {
          for (let j = 0; j < productChunksItem.length; j++) {
            const priceItem = productChunksItem[j];
            priceItem.price = res.get(priceItem.skuId)|| null;
          }
          skuChunksCount--;
          if (skuChunksCount == 0) {
            resolve({productList: productList});
          }
        })
      }
    })
  }

  // 重载列表方法
  const reloadPlist = (data: {
    cateId: null | string | number;
    pageNum: string | number;
    pageSize: string | number;
    extAttrList: any[];
  }, callback: any) => {
    colorAjax(
      'post',
      {
        functionId: 'icSelectionSkuInfo',
        appid: 'mro-lectotype'
      },
      data,
      ({data}: any) => {
        if (data.success) {
          const d = data?.data;
          const result = getFormat(d);
          // 获取商品价格
          getPriceOfProductList(result.productList).then((res: any) => {
            result.productList = res.productList;
            setTimeout(() => {
              callback(result)
            }, 0)
          })
        } else {
          callback(initData)
        }
      })

    // getList(data)
    //   .then((res: any) => {
    //     if (res.success) {
    //       const data = res?.data;
    //       const result = getFormat(data);
    //       // 获取商品价格
    //       getPriceOfProductList(result.productList).then((res: any) => {
    //         result.productList = res.productList;
    //         setTimeout(() => {
    //           callback(result)
    //         }, 0)
    //       })
    //
    //     } else {
    //       callback(initData)
    //     }
    //   })
    //   .catch(e => {
    //     console.log(e);
    //     callback(initData);
    //   });
  }

  // 初始化产品选型组件
  const initGetPlist = (data: {
    cateId: null | string | number;
    pageNum: string | number;
    pageSize: string | number;
    extAttrList: { attrId: string, attrValueId: string }[];
  }) => {
    /* @ts-ignore */
    // const content = getThemeColor() + '-' + window?.BrowserVersion
    // const s1 = getS2(content, data.cateId + '');

    colorAjax(
      'post',
      {
        functionId: 'icSelectionSkuInfo',
        appid: 'mro-lectotype'
      },
      data,
      ({data}: any) => {
        if (data.success) {
          const d = data?.data
          const result = getFormat(d);
          if (!result?.productList || result?.productList?.length < 1) {
            setModelMc({
              componentId: '50',
              customData: [],
            });
            return
          }
          // 获取商品价格
          getPriceOfProductList(result.productList).then((res: any)=>{
            result.productList = res.productList;
            setModelMc({
              componentId: '50',
              customData: result,
            });
          })
        } else {
          setModelMc({
            componentId: '50',
            customData: initData,
          });
        }
      })

    // getList(data, { "X-Jd-M": hex_md5(formatQueryData(content, s1))})
    //   .then((res: any) => {
    //     if (res.success) {
    //       const data = res?.data
    //       const result = getFormat(data);
    //       if (result.productList.length < 1) {
    //         setModelMc({
    //           componentId: '50',
    //           customData: [],
    //         });
    //         return
    //       }
    //       // 获取商品价格
    //       getPriceOfProductList(result.productList).then((res: any)=>{
    //         result.productList = res.productList;
    //         setModelMc({
    //           componentId: '50',
    //           customData: result,
    //         });
    //       })
    //     } else {
    //       setModelMc({
    //         componentId: '50',
    //         customData: initData,
    //       });
    //     }
    //   })
    //   .catch(e => {
    //     console.log(e);
    //   });
  }

  // 不频繁更新选型组件，只做数据运算
  const produceSelectModel = useMemo(() => {
    return <div style={{ height: '979px' }}>
      {
        /*@ts-ignore*/
        window?.MagicCuberLib && <>
          {/* @ts-ignore react/jsx-no-undef */}
          <MagicCuberLib.CubeComp option={modelMc} onActionHandler={onActionHandler1008} />
        </>
      }
    </div>
  }, [modelMc])

  useEffect(() => {
    setTimeout(() => {
      setinitLoading(false)
    }, 1000)
    if (modelMc?.customData && modelMc?.customData?.productList?.length > 0) {
      setShowMessage(true)
    } else {
      setShowMessage(false)
    }

  }, [modelMc])

  useEffect(() => {
    pageframeOnload().then(() => {
      initGetPlist({
        cateId: itemObj?.cateSelected,
        pageNum: pageObj.pageNum,
        pageSize: pageObj.pageSize,
        extAttrList: []
      })
    });
  }, [itemObj])

  return (
    <div id="MagicCuberLib">
      <div style={{ display: showMessage ? 'block' : 'none' }}>
        {produceSelectModel}
      </div>
      {
        !showMessage && <div style={{ height: '584px', textAlign: 'center', background: '#fff', paddingTop: '150px', boxSizing: 'border-box' }}>
          <img style={{ width: '152px', height: '152px' }} src="https://img11.360buyimg.com/imagetools/jfs/t1/194518/1/31644/19631/63d90345F55a658d5/1be25506b636d65a.png" alt="暂无记录"/>
          <p style={{marginTop: '16px', fontSize: '16px', color: 'rgba(204,204,204,1)' }}>{initLoading ? '加载中...' : '暂无记录'}</p>
        </div>
      }
    </div>
  )
}
export default Index;
