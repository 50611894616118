import React, {useState} from 'react';
import './home.scss';
const kj = require('@/common/images/ks.png');
const jz = require('@/common/images/jz.png');
const qm = require('@/common/images/qm.png');
const accurateIcon = "https://img11.360buyimg.com/imagetools/jfs/t1/107184/6/41675/933/655f150fF276d4862/7a7bab5a5bc54eb0.png";
const diamondIcon = "https://img12.360buyimg.com/imagetools/jfs/t1/226800/25/3827/1127/655f150fFd2725582/8fff141ffed97517.png";
const lightningIcon = "https://img10.360buyimg.com/imagetools/jfs/t1/227475/23/4038/1720/655f150fFaf6e5060/51f4e06e3099ac23.png";

import ProductModel from "@/components/Home/productModel";
import ItemSelection from '@/components/Home/ItemSelection';

const Home = () => {
  const [itemObj, setItemObj] = useState<{ cateSelected: string; cateStr: string }>({ cateSelected: '', cateStr: '' });
  
  return (
    <div className="model">
      <div className="m">
        <div className="m_top">
          <div className="m_top_title">专业选型工具</div>
          <div className="m_top_message">
            <div>
              <img src={diamondIcon} alt="" />
              <div>更专业</div>
            </div>
            <span></span>
            <div>
              <img src={lightningIcon} alt="" />
              <div>更快速</div>
            </div>
            <span></span>
            <div>
              <img src={accurateIcon} alt="" />
              <div>更精准</div>
            </div>
            {/* <div className="message_box">
              <div className="message_box_title"><img src={kj} alt=""/>更快捷</div>
              <div>快速锁定意向产品</div>
            </div>
            <div className="message_box">
              <div className="message_box_title"><img src={jz} alt=""/>更精准</div>
              <div>精准匹配唯一结果</div>
            </div>
            <div className="message_box">
              <div className="message_box_title"><img src={qm} alt=""/>更全面</div>
              <div>全系列参数展示</div>
            </div> */}
          </div>
        </div>
        <div className="m_item">
          <ItemSelection changeItem={(obj: { cateSelected: string; cateStr: string }) => setItemObj(obj)}></ItemSelection>
        </div>
        <div className="m_model">
          <ProductModel itemObj={itemObj}></ProductModel>
        </div>
      </div>
    </div>
  )
}
export default Home;
