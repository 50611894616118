import axios from "axios"
import SHA256 from "@/utils/sha256";
import { stringify } from "qs";
import { getCookie } from '@/utils'

const isBeta = false

const http = axios.create({
  // 超时时间
  timeout: 1000000,
  // 请求头
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials:true
});

// axios实例的请求拦截器
http.interceptors.request.use(
  function(config) {
    if (
      config.data &&
      config.headers["Content-Type"] === "application/x-www-form-urlencoded"
    ) {

      config.data = stringify(config.data);
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

/*
* color前置接口数据处理
* @params method 方法
* @params urlData url拼装参数
* @params data 传输数据
* @params callback 回调函数，接口请求成功之后获得的数据
* */
const colorAjax = (method, urlData, data, callback) => {
  const newDate = new Date().getTime()

  const params = {
    functionId: urlData.functionId,
    appid: urlData.appid,
    clientVersion: "1.1.0",
    client: "pc",
    t: newDate,
    body: JSON.stringify(data)
  };

  const paramsSign = {
    ...params,
    body: SHA256(params.body).toString()
  };

  params['loginType'] = '3';
  params['uuid'] = getCookie('__jda');

  // @ts-ignore
  const PSign =  window.PSign

  PSign.sign(paramsSign).then(signedParams => {
    const h5st = encodeURI(signedParams.h5st)

    // @ts-ignore
    window.getJsToken(function(res){
      if (res && res.jsToken) {
        if (method === 'post') {
          const url = isBeta ?
            `//beta-api.m.jd.com/api?functionId=beta_${urlData.functionId}&t=${newDate}&client=pc&clientVersion=1.1.0&appid=${urlData.appid}&h5st=${h5st}&x-api-eid-token=${res.jsToken}` :
            `//api.m.jd.com/api?functionId=${urlData.functionId}&t=${newDate}&client=pc&clientVersion=1.1.0&appid=${urlData.appid}&h5st=${h5st}&x-api-eid-token=${res.jsToken}`
          // const url = `//api.m.jd.com/api?functionId=${urlData.functionId}&t=${newDate}&client=pc&clientVersion=1.1.0&appid=${urlData.appid}&h5st=${h5st}&x-api-eid-token=${res.jsToken}`
          http.post(url, {
            body: JSON.stringify(data)
          }, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "x-api-eid-token": res.jsToken
            }
          })
            .then((res) => {
              // do something
              callback && callback(res)
            });
        }
        if (method === 'get') {
          const url = `//api.m.jd.com/api?functionId=${urlData.functionId}&t=${newDate}&client=pc&clientVersion=1.1.0&appid=${urlData.appid}&h5st=${h5st}&body=${JSON.stringify(data)}&x-api-eid-token=${res.jsToken}`

          http.get(url, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "x-api-eid-token": res.jsToken
            }
          })
            .then((res) => {
              // do something
              callback && callback(res)
            });
        }
      }
    }, 500)
  });
}

export {
  colorAjax
}
