import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from "./router";

// @ts-ignore
const ParamsSign = window.ParamsSign
if (ParamsSign) {
  // @ts-ignore
  window.PSign = new ParamsSign({
    appId: "93ac7",
    debug: false, 
    preRequest: false, 
    onSign:()=>{}, 
    onRequestTokenRemotely:()=>{}, 
    onRequestToken:()=>{}
  })
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// 解决键盘事件 D S T的问题
setTimeout(() => {
  document.onkeyup = () => {}
}, 1000)


root.render(
  <Router></Router>
);
