import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { pageframeOnload } from '@/utils';
import { getItem } from '@/axios/home';

let closefunc: any = null

/* eslint-disable */
const Index = (props: { changeItem: any }) => {
  const { changeItem } = props;
  const [modelMc, setModelMc] = useState({}); // 解析后数据


  // 组件内事件
  const onActionHandler49 = (data: any) => {
    if (data.actionType === "cate_item_change") {
      changeItem && changeItem({
        cateSelected: data.cateSelected,
        cateStr: data.cateStr
      })
    }
    if (data.actionType === "cate_panel_hidden_callback") {
      closefunc = data.panelHidden
    }
  };

  const removeDeepLevelWithoutCateImg = (data: any, currentDepth: any) => {
    if (!Array.isArray(data) || currentDepth >= 5) return data;

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      // 如果当前深度为3并且我们正在查看第四级数据
      if (currentDepth === 4) {
        if (!item.cateImg) {
          // 移除这个项目以及其后的所有项目
          data.splice(i);
          i--; // 重新评估当前位置的下一个元素
        }
      } else if (item.childCates && Array.isArray(item.childCates)) {
        item.childCates = removeDeepLevelWithoutCateImg(item.childCates, currentDepth + 1);
      }
    }
    return data;
  }


  useEffect(() => {
    pageframeOnload().then(() => {
      getItem({ id: 132614 })
        .then((res: any) => {
        if (res.success) {
          setModelMc({
            componentId: '49',
            customData: {
              "cateArray": [...res.data]
            },
          });
        } else {
          setModelMc({
            componentId: '49',
          });
        }
      });
    });
  }, [])

  const clickDocument = useCallback((e: any) => {
    e.stopPropagation()
    closefunc && closefunc()
  }, [closefunc])

  useEffect(() => {
    const body = document.querySelector('body')

    body?.addEventListener('click', clickDocument)
    return () => {
      body?.removeEventListener('click', clickDocument)
    }
  })

  const itemSelection = useMemo(() => {
    if (Object.values(modelMc).length < 1) {
      return <></>
    } else {
      return <>
        {
          /*@ts-ignore*/
          window?.MagicCuberLib && <>
            {/* @ts-ignore react/jsx-no-undef */}
            <MagicCuberLib.CubeComp option={modelMc} onActionHandler={onActionHandler49} />
          </>
        }
      </>
    }
  }, [modelMc])

  return <>
    {itemSelection}
  </>
}
export default Index
