import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './views/Home/Home';

const routes = [
  {
    path: `/`,
    name: 'Home',
    meta: {
      title: '产品选型',
    },
    component: Home,
  },
]

const BasicRoute = () => (
  <BrowserRouter>
    <Routes>
      {routes.map((e: any, index: number) => {
        return <Route key={index} path={e.path} element={e.component()}/>
      })}
    </Routes>
  </BrowserRouter>
);

export default BasicRoute;
