import { messageHttp, http } from './index';
// @ts-ignore
import CryptoJS from 'crypto-js';
import { getCookie } from '@/utils';

/***
 * 获取魔方数据
 */
// export function getList(params: any, headers?: any) {
//   return http('post', `https://beta-api.m.jd.com/api?functionId=beta_icSelectionSkuInfo&t=${new Date().getTime()}&client=pc&clientVersion=1.1.0&appid=mro-lectotype`, {body: params}, headers);
// }

/***
 * 获取类目列表信息
 */
export function getItem(params: any) {
  return http('get', '/api/ic/selection/category', params);
}

/**
 * 获取商品价格
 */
export function getPrice(params: any) {
  return new Promise((resolve, reject)=>{
    const apiParam = {
      skuPriceInfoRequestList: params.allSkuList.map((skuId: string)=>{
        return {
          skuId: skuId
        }
      })
    }
    const mainTimeStamp = new Date().getTime();
    const jda_uuid : string = getCookie('__jda') || '';
    const colorParam: any = {
      appid: 'mro-lectotype',
      functionId: 'mzhprice_getCustomRealPriceInfoForColor',
      client: 'pc',
      clientVersion: '1.0.0',
      t: mainTimeStamp,
      body: JSON.stringify(apiParam)
    }
    const colorParamSign = {
      ...colorParam,
      // 签名参数body加密
      body: CryptoJS.SHA256(colorParam.body).toString(CryptoJS.enc.Hex)
    }
    // 原始参数增加非签名参数
    colorParam['loginType'] = '3';
    colorParam['uuid'] = jda_uuid;
    // @ts-ignore
    window.PSign && window.PSign.sign(colorParamSign).then(signedParams =>{
      colorParam['h5st'] = encodeURI(signedParams.h5st)
      // @ts-ignore
      window.getJsToken(function(res){
        if (res && res.jsToken){
          colorParam['x-api-eid-token'] = res.jsToken;
        }
      }, 500)
      http('get', 'https://api.m.jd.com/api', colorParam).then((res: any)=>{
        if (res.success) {
          const skuPriceInfoResponseList = res.skuPriceInfoResponseList || [];
          const skuPriceInfo = new Map();
          skuPriceInfoResponseList.map((skuPriceItem: any) => {
            skuPriceInfo.set(skuPriceItem.skuId, skuPriceItem.priceResult ? skuPriceItem.priceResult.jdPrice : "0")
          })
          resolve(skuPriceInfo)
        }else{
          reject({"errorCode": res.errorCode, "errorMsg": res.errorMsg})
        }
      });
    })
  })
}
